
<template>
  <div class="leave">
    <el-card>
      <el-form :model="from">
        <el-form-item label="最新消息时间：">
          <el-radio-group v-model="from.value" @change="changeTime">
            <el-radio :label="0">不限</el-radio>
            <el-radio :label="1">近三天</el-radio>
            <el-radio :label="2">近一周</el-radio>
            <el-radio :label="3">近一个月</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card style="margin-top: 20px">
      <div class="leave_table">
        <div
          class="leave_table_li"
          @click="tabClick(index)"
          v-for="(item, index) in tabList"
          :key="index"
          :class="tabIndex === index ? 'active' : ''"
        >
          {{ item }}<span v-show="tabIndex === index">({{ count }})</span>
        </div>
      </div>
      <div class="leave_list">
        <div
          class="leave_list_item"
          v-for="(item, index) in messageList"
          :key="index"
        >
          <div class="leave_list_item_left">
            <img :src="item.headImage" alt="" />
            <span v-if="item.isRead">已回复</span>
          </div>
          <div class="leave_list_item_right">
            <div class="leave_list_item_right_list">
              <div class="block_up">
                <div
                  class="block"
                  v-for="(tt, ii) in item.communicationsDetailsViews"
                  :key="ii"
                >
                  <div class="leave_list_item_right_list_p">
                    <p>{{ tt.content }}</p>
                    <div class="cosle" @click="deleteClose(tt)">删除</div>
                  </div>
                  <span>{{ tt.createTime }}</span>
                </div>
              </div>
              <div class="huifu" @click="messageClick(item.userId)">
                回复<i class="el-icon-paperclip"></i>
              </div>
              <div class="sanjiao"></div>
            </div>
            <el-button type="text" @click="messageClick(item.userId)"
              >全部消息</el-button
            >
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
const defaultQueryInfo = {
  pageNumber: 0,
  pageSize: 1000,
};
import { getCommuniList, deleleteCommun } from "@/api/message.js";
export default {
  name: "Leave",
  data() {
    return {
      queryInfo: { ...defaultQueryInfo },
      from: {
        value: 0,
      },
      count: 0,
      tabIndex: 0,
      tabList: ["全部", "已回复", "未回复"],
      messageList: [], //聊天数据
    };
  },
  created() {
    this.search();
  },
  methods: {
    //路由跳转聊天
    messageClick(id) {
      this.$router.push({
        path: "/message/leaveInfo",
        query: { userId: id },
      });
    },
    //删除
    deleteClose(row) {
      this.$confirm(`确认删除${row.content}这条评论吗 ?`, {
        type: "warning",
      })
        .then(async () => {
          const { data: res } = await deleleteCommun({ id: row.id });

          if (res.success) {
            this.$messageSuccess(`${row.content}评论删除成功`);
            this.search();
          }
        })
        .catch(() => {});
    },
    //tab切换
    tabClick(index) {
      this.tabIndex = index;
      if (index === 0) {
        this.search();
      } else if (index === 1) {
        this.queryInfo.inRead = true;
        this.search();
        delete this.queryInfo.inRead;
      } else if (index === 2) {
        this.queryInfo.unRead = true;
        this.search();
        delete this.queryInfo.unRead;
      }
    },
    //选择时间
    changeTime(val) {
      if (val === 0) {
        this.search();
      } else if (val === 1) {
        this.queryInfo.thereUpdateTime = "1";
        this.search();
        delete this.queryInfo.thereUpdateTime;
      } else if (val === 2) {
        this.queryInfo.weekUpdateTime = "2";
        this.search();
        delete this.queryInfo.weekUpdateTime;
      } else if (val === 3) {
        this.queryInfo.monthUpdateTime = "3";
        this.search();
        delete this.queryInfo.monthUpdateTime;
      }
    },
    //获取交流分页
    async search() {
      const { data: res } = await getCommuniList(this.queryInfo);
      if (res.code === 10200) {
        this.messageList = res.data.data;
        this.count = res.data.pageTotal;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.leave {
  width: 100%;

  /deep/ .el-form-item {
    margin-bottom: 0;
  }

  /deep/ .el-form-item__label {
    color: #252631;
    font-size: 14px;
    font-weight: bold;
  }

  .leave_table {
    display: inline-block;
    overflow: hidden;
    box-sizing: border-box;
    padding: 5px 10px;
    background-color: #f6f6f6;
    border-radius: 2px;

    .leave_table_li {
      cursor: pointer;
      margin-right: 40px;
      box-sizing: border-box;
      float: left;
      padding: 8px 15px;
      overflow: hidden;
      border-radius: 2px;
      color: #151515;
      font-weight: 400;
      font-size: 14px;
    }

    .leave_table_li:nth-last-child(1) {
      margin-right: 0;
    }

    .active {
      background-color: #8483d0;
      color: #ffffff;
    }
  }

  .leave_list {
    width: 100%;
    overflow: hidden;
    margin-top: 30px;

    .leave_list_item {
      width: 100%;
      overflow: hidden;
      margin-bottom: 16px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      .leave_list_item_left {
        width: 52px;
        margin-right: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
          width: 52px;
          height: 52px;
          border-radius: 2px;
          display: block;
        }

        span {
          box-sizing: border-box;
          border-radius: 2px;
          background-color: rgba(132, 131, 208, 0.1);
          display: inline-block;
          width: 52px;
          height: 24px;
          line-height: 24px;
          color: #8483d0;
          font-weight: 400;
          font-size: 14px;
          text-align: center;
          margin-top: 4px;
        }
      }

      .leave_list_item_right {
        flex: 1;
        box-sizing: border-box;
        padding: 20px;
        position: relative;
        border-radius: 2px;
        background-color: #f6f6f6;

        .leave_list_item_right_list {
          width: 100%;
          overflow: hidden;
          align-items: center;
          display: flex;
          justify-content: space-between;

          .block_up {
            flex: 1;
            overflow: hidden;
          }
          .block {
            width: 100%;
            overflow: hidden;
            margin-bottom: 20px;

            .leave_list_item_right_list_p {
              width: 100%;
              overflow: hidden;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 10px;

              p {
                flex: 1;
                font-size: 16px;
                color: #151515;
                font-weight: 400;
              }
              .cosle {
                width: 56px;
                cursor: pointer;
                margin-left: 24px;
                color: #999999;
                font-weight: 400;
                font-size: 14px;
                display: block;
              }
            }
            span {
              color: #999999;
              font-size: 12px;
              font-weight: 400;
            }
          }

          .huifu {
            color: #8483d0;
            font-size: 12px;
            font-weight: 400;
            cursor: pointer;
          }

          .sanjiao {
            width: 0;
            height: 0;
            position: absolute;
            left: -8px;
            top: 10px;
            border-bottom: 5px solid transparent;
            border-top: 5px solid transparent;
            border-right: 10px solid #f6f6f6;
          }
        }
      }
    }
  }
}
</style>