import request from "@/utils/request"

//交流的分页查询
export function getCommuniList(data) {
    return request({
        url: '/communications/listPage',
        method: 'get',
        params: data
    })
}

//删除回复
export function deleleteCommun(data) {
    return request({
        url: '/communications/deleteById',
        method: 'delete',
        params: data
    })
}

//获取单个回复的数据
export function listDetails(data) {
    return request({
        url: '/communications/listDetails',
        method: 'get',
        params: data
    })
}

//回复新增
export function communicationsSave(data) {
    return request({
        url: '/communications/save',
        method: 'post',
        data
    })
}


//审核通过
export function panelDiscussion(data) {
    return request({
        url: '/panelDiscussion/isShow',
        method: 'get',
        params: data
    })
}

//审核通过
export function panelDiscussionDetele(data) {
    return request({
        url: '/panelDiscussion/deleted',
        method: 'delete',
        params: data
    })
}